// @flow
import React, { useState, useEffect, memo } from "react"
import isEqual from "lodash/isEqual"
import { RichText } from "prismic-reactjs"
import LazyLoad from "react-lazyload"

import {
  images,
  htmlSerializerUpdateStyle,
  formatRichTextLink,
  linkResolver,
  isBrowser,
} from "@lesmills/gatsby-theme-common"

type HeroProps = {|
  data: Object,
  handleClickDiscover?: () => void,
  isNewSalesLandingPage?: Boolean,
|}

const Hero = ({
  data,
  handleClickDiscover = () => {},
  isNewSalesLandingPage = false,
}: HeroProps) => {
  const [disableArrow, setDisableArrow] = useState(false)
  const {
    hero_title = {},
    hero_subtitle = {},
    start_free_trial_button_label = {
      raw: [],
    },
    see_prices_button_label = {
      raw: [],
    },
    cancel_free_trial_note = {},
    discover_button_label = {},
    join_us_content = {},
    hero_video = {},
    hero_image = {},
    hero_logo = {},
  } = data || {}

  const urlHeroImg = hero_image ? hero_image.url : ""
  const mobileHeroImg = hero_image.thumbnails
    ? hero_image.thumbnails.mobile
    : {}
  const heroVideoUrl = hero_video && hero_video.raw && hero_video.raw.url

  useEffect(() => {
    window.addEventListener("resize", handleDisableArrow)
    return () => {
      window.removeEventListener("resize", handleDisableArrow)
    }
  }, [])

  const handleDisableArrow = () => {
    if (isBrowser && window.innerHeight <= 600 && !disableArrow) {
      setDisableArrow(true)
    } else if (window.innerHeight > 600) {
      setDisableArrow(false)
    }
  }

  return (
    <>
      {!isNewSalesLandingPage && join_us_content ? (
        <div className="bg-gray-400 py-4 text-center">
          <RichText
            render={join_us_content.raw}
            htmlSerializer={htmlSerializerUpdateStyle(
              "",
              "",
              "",
              "",
              "",
              "",
              undefined, // This function was called after image was loaded
              "_self",
              "",
              "",
              "text-white font-primary text-base uppercase leading-24 md:text-3xl mx-auto max-w-295 md:max-w-1024 join-us-content"
            )}
          />
        </div>
      ) : null}
      <section
        className={`relative bg-black md:bg-left-top overflow-hidden h-100vh w-full flex items-center px-20 slp-hero-wrapper`}
      >
        {heroVideoUrl ? (
          <LazyLoad once height="h-full">
            <video
              autoPlay="autoPlay"
              loop="loop"
              muted="muted"
              playsInline="playsInline"
              className="absolute top-0 left-0 object-cover h-full w-full"
            >
              <source src={heroVideoUrl} type="video/mp4"></source>
            </video>
          </LazyLoad>
        ) : (
          <LazyLoad once height="h-full">
            <picture>
              <source srcSet={urlHeroImg} media="(min-width: 768px)" />
              <source srcSet={mobileHeroImg.url} media="(max-width: 768px)" />
              <img
                alt={hero_image ? hero_image.alt : ""}
                src={hero_image ? hero_image.url : ""}
                className="absolute left-0 w-full h-full object-cover object-top top-0"
                data-cy="hero-img"
              />
            </picture>
          </LazyLoad>
        )}
        <div className="relative z-20 pt-35/100 text-white w-288 xs_sm:w-full sm:w-full md:tf-y-1/2 mx-auto">
          <LazyLoad once>
            <img
              src={hero_logo.url}
              className="w-120 h-auto md:w-158 mb-25 ml-auto mr-auto"
              alt={hero_logo.alt}
            />
          </LazyLoad>
          <RichText
            render={hero_title.raw}
            htmlSerializer={htmlSerializerUpdateStyle(
              "",
              "",
              "",
              "",
              "",
              "",
              undefined, // This function was called after image was loaded
              "_self",
              "",
              "",
              "font-primary text-center uppercase ls-0.53 sm:ls-1.45 text-6xl sm:text-refined-hero-title-sm text-white sm:leading-48 leading-30 sm:mb-25 mb-15"
            )}
          />
          <RichText
            render={hero_subtitle.raw}
            linkResolver={linkResolver}
            htmlSerializer={htmlSerializerUpdateStyle(
              "",
              "",
              "",
              "",
              "",
              "",
              undefined, // This function was called after image was loaded
              "_self",
              "",
              "",
              "",
              "font-inter-regular text-white text-base sm:text-2lg sm:leading-24 leading-22 mt-20 mb-20 text-center sm:mx-auto" // h3 style
            )}
          />
          <div className="flex md:flex-row flex-col justify-center items-center">
            {formatRichTextLink(
              start_free_trial_button_label.raw,
              "uppercase text-black bg-green-460 hover:text-white hover:bg-gray-970 cursor-pointer text-base sm:text-xl font-primary py-8 md:mr-15 sm:mb-20 mb-10 text-center no-underline w-288 md:w-252"
            )}
            {formatRichTextLink(
              see_prices_button_label.raw,
              "uppercase bg-black border-green-460 text-green-460 cursor-pointer text-base sm:text-xl box-content py-8 font-primary border sm:mb-20 mb-10 text-center no-underline w-288 md:w-252"
            )}
          </div>
          <RichText
            render={cancel_free_trial_note.raw}
            htmlSerializer={htmlSerializerUpdateStyle(
              "",
              "text-white font-base-light text-3xs sm:ls-0.21 ls-0.16 text-center mt-10 xs_sm:mt-0 sm:mt-0"
            )}
          />
        </div>
        <div
          className={`absolute text-center hero-arrow bottom-10 md:bottom-32 z-20 ${
            disableArrow ? "hidden" : ""
          }`}
        >
          <p
            className="font-primary text-white text-3xs md:text-base uppercase block text-center cursor-pointer"
            onClick={() => handleClickDiscover()}
          >
            {discover_button_label.text}
          </p>
          <a
            onClick={() => handleClickDiscover()}
            className="block md:inline-block"
          >
            <LazyLoad once>
              <picture>
                <source
                  srcSet={images.downArrowMedium}
                  media="(min-width: 768px)"
                />
                <source
                  srcSet={images.downArrowSmall}
                  media="(max-width: 768px)"
                />
                <img
                  src={images.downArrowMedium}
                  alt="scroll-down"
                  className="cursor-pointer relative m-auto sm:h-hero-scroll-mb md:h-auto md:w-auto w-30"
                  data-cy="arrow-icon"
                />
              </picture>
            </LazyLoad>
          </a>
        </div>
      </section>
    </>
  )
}

export default memo(Hero, (prevProps, nextProps) => {
  return isEqual(prevProps.data, nextProps.data)
})
